import React, {FC, useEffect, useState} from 'react';

import jwtDecode from 'jwt-decode';

// Translation
import i18next from 'i18next';

// Master Hooks
import { useAppDispatch } from '../src/Services/MasterStore/MasterHook';

// React Router
import { useNavigate, useLocation  } from 'react-router-dom';

// Slices
import {clearStore} from '../src/Services/MasterStore/Reducers/ProductSlice';

//style
import './App.css';

//routes
import {RoutesProvider} from './Routes/RouteProvider';
import RequestProvider from './Middleware/Requests/RequestProvider';

const App: FC = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [Token] = useState(localStorage.getItem('token'));

  useEffect(()=>{
    if(location.pathname !== '/productDetails'){
      dispatch(clearStore());     
    }
      const decodedToken = Token && jwtDecode<any>(Token);
      const currentTime = Date.now() / 1000;
      if (decodedToken && decodedToken.exp < currentTime) {
        localStorage.clear();
        Navigate('/', { replace: true })
      }
      i18next.changeLanguage(localStorage.LANG).then();
  },[Token, Navigate, location, dispatch]);

  return (
    <React.Fragment>
      <RequestProvider>
        <RoutesProvider>
        </RoutesProvider>
      </RequestProvider>
    </React.Fragment>
  )
}

export default App